import React, { Component } from 'react'
import _get from 'lodash/get'
import { graphql } from 'gatsby'
import AdSense from 'react-adsense';
import reactStringReplace from 'react-string-replace'
import MediaQuery from 'react-responsive'
import { scroller, Element } from 'react-scroll';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import Content from '../components/Content'
import NewsletterForm from '../components/NewsletterForm'
import Checklist from '../components/Checklist'
import OutlinedChips from '../components/OutlinedChips'
import OrderedList from '../components/OrderedList'
import UnorderedList from '../components/UnorderedList'
import SummaryGrid from '../components/SummaryGrid'
import SectionDivider from '../components/SectionDivider'
import Footer from '../components/Footer'
import RecipeGrid from '../components/RecipeGrid'
import SocialSharing from '../components/SocialSharing'

import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import './SinglePost.css'

var shuffle = require('shuffle-array');

const InContentAd = (key) => {
  return (
    <div>
    </div>
  )
}

const BeforeIngredientsAd = (key) => {
  return (
    <div>
    </div>
  )
}

const BeforeTipsAd = (key) => {
  return (
    <div>
    </div>
  )
}

var SidebarAd = (key) =>
  <div>
    <center>
      <div data-premium="" data-adunit="MEU_CREME_BRULEE_LATERAL_01" data-sizes-desktop="[[300,600]]" >
      </div>
    </center>
  </div>

var AnchorAd = (props) =>
  <div>
    <MediaQuery maxDeviceWidth={750 - 1}>
      <AppBar position="fixed" color="primary" style={{top: 'auto', bottom: 0, backgroundColor: 'white', borderStyle: 'solid', borderWidth: '2px', borderColor: '#f3f3f3', boxShadow: '1'}}>
        <div style={{ display: 'inline-block', margin: 'auto' }}>
          <SocialSharing
            url={props.url}
            media={props.media}
          />
        </div>
      </AppBar>
    </MediaQuery>
  </div>

class SingleRecipeTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = { currentPageIndex: 0 };
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
  }

  changePage = (direction) => {
    this.setState({
      currentPageIndex: this.state.currentPageIndex + direction
    })
    scroller.scrollTo('scrollToElement', {
      duration: 1000,
      delay: 0,
      smooth: true
    })
  }

  previousPage = () => {
    this.changePage(-1)
  }

  nextPage = () => {
    this.changePage(+1)
  }

  componentDidUpdate(prevProps, prevState) {
    if (typeof window.ga === 'function') {
      window.ga('send', 'pageview', prevProps.fields.slug)
    }
  }

  render() {
    let { title, body, pictures, portions, preparation, cooking, difficulty,
          ingredients, steps, tips, tags, recipes } = this.props;
    const { currentPageIndex } = this.state;

    let pages = body.split();

    let currentPage = pages[currentPageIndex].trim().replace("</p>", "</p><!--incontent-->");

    currentPage = reactStringReplace(currentPage, "<!--incontent-->", (match, i) => (
      <InContentAd />
    ));

    if (pictures) {
      pictures = pictures.map(picture => ({
        original: `${picture.image}-/resize/1000x1000/`,
        thumbnail: `${picture.image}-/resize/250x250/`,
      }));
    };

    return (
      <main>
        <article
          className="SinglePost section light"
          itemScope
          itemType="http://schema.org/BlogPosting"
        >

          <div className="container skinny">
            {title && (
              <h1 className="SinglePost--Title" itemProp="title">
                {title}
              </h1>
            )}
            <Element name="scrollToElement" />
            <div className="SinglePost--Content relative">
              <MediaQuery minDeviceWidth={750}>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <div className="SinglePost--Sidebar">
                      <SocialSharing
                        url={this.props.fields.slug}
                        media={this.props.frontmatter.featuredImage}
                        size={42}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <div className="SinglePost--InnerContent SingleRecipe--InnerContent">
                      {tags && <OutlinedChips items={tags.map(tag => ({title: tag.tag}))}/>}
                      {currentPage.map(part => (typeof part === "string" ? <Content
                        source={part.trim()}
                        /> : part))}
                      {pictures && <ImageGallery items={pictures} />}
                      <SectionDivider/>
                      <SummaryGrid portions={portions} preparation={preparation}
                                   cooking={cooking} difficulty={difficulty}/>
                      <BeforeIngredientsAd />
                      <SectionDivider title='Ingredientes'/>
                      {ingredients && <Checklist items={ingredients.map(ingredient => ingredient.excerpt)}/>}
                      <SectionDivider title='Modo de Preparo'/>
                      {steps && <OrderedList items={steps.map(step => step.step)}/>}
                      <BeforeTipsAd />
                      <SectionDivider title='Dicas'/>
                      {tips && <UnorderedList items={tips.map(tip => tip.tip)}/>}
                      <NewsletterForm
                        name="Newsletter"
                        headline="Você gostou da receita? Inscreva-se para receber conteúdos exclusivos por email."
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="SinglePost--Sidebar">
                      <SidebarAd key={Math.random()} />
                    </div>
                  </Grid>
                </Grid>
              </MediaQuery>
              <MediaQuery maxDeviceWidth={750 - 1}>
                <div className="SinglePost--InnerContent SingleRecipe--InnerContent">
                  {tags && <OutlinedChips items={tags.map(tag => ({title: tag.tag}))}/>}
                  {currentPage.map(part => (typeof part === "string" ? <Content
                    source={part.trim()}
                    /> : part))}
                  {pictures && <ImageGallery items={pictures} />}
                  <SectionDivider/>
                  <SummaryGrid portions={portions} preparation={preparation}
                               cooking={cooking} difficulty={difficulty}/>
                  <BeforeIngredientsAd />
                  <SectionDivider title='Ingredientes'/>
                  {ingredients && <Checklist items={ingredients.map(ingredient => ingredient.excerpt)}/>}
                  <SectionDivider title='Modo de Preparo'/>
                  {steps && <OrderedList items={steps.map(step => step.step)}/>}
                  <BeforeTipsAd />
                  <SectionDivider title='Dicas'/>
                  {tips && <UnorderedList items={tips.map(tip => tip.tip)}/>}
                  <NewsletterForm
                    name="Newsletter"
                    headline="Você gostou da receita? Inscreva-se para receber conteúdos exclusivos por email."
                  />
                </div>
              </MediaQuery>
            </div>
            <MediaQuery minDeviceWidth={750}>
              <RecipeGrid title='' tiles={recipes} subject='Receitas' slug='/recipes' ad={[1]} />
            </MediaQuery>
            <MediaQuery maxDeviceWidth={750 - 1}>
              <div className="container">
                <RecipeGrid title='' tiles={recipes} subject='Receitas' slug='/recipes' ad={[1]} />
              </div>
            </MediaQuery>
            <AnchorAd
              url={this.props.fields.slug}
              media={this.props.frontmatter.featuredImage}
            />
          </div>
        </article>
        <Footer />
      </main>
    )
  }
}


// Export Default SinglePost for front-end
const SingleRecipe = ({ data: { post, allPosts, relatedRecipes } }) => {
  const thisEdge = allPosts.edges.find(edge => edge.node.id === post.id)

  var relatedRecs = relatedRecipes.edges.filter(edge => edge.node.frontmatter.status == 'Published');
  relatedRecs = relatedRecs.filter(edge => edge.node.id != post.id);

  return (
    <SingleRecipeTemplate
      {...post}
      {...post.frontmatter}
      body={post.html}
      nextPostURL={_get(thisEdge, 'next.fields.slug')}
      nextPostTitle={_get(thisEdge, 'next.frontmatter.title')}
      recipes={shuffle.pick(relatedRecs, { 'picks': 4 })}
    />
  )
}

export default SingleRecipe

export const pageQuery = graphql`
  ## Query for SingleRecipe data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SingleRecipe($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        template
        pictures {
          image
        }
        portions
        preparation
        cooking
        difficulty
        ingredients {
          excerpt
        }
        steps {
          step
        }
        tips {
          tip
        }
        categories {
          category
        }
        tags {
          tag
        }
        featuredImage
      }
      fields {
        slug
      }
    }

    allPosts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "recipes" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
        }
        next {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        previous {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }

    relatedRecipes: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "recipes" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            status
            categories {
              category
            }
            tags {
              tag
            }
            featuredImage
          }
        }
      }
    }
  }
`
