import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import LocalPizzaIcon from '@material-ui/icons/LocalPizza';
import TimerIcon from '@material-ui/icons/Timer';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';
import SpeedIcon from '@material-ui/icons/Speed';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: 15
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    margin: 5,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 12,
  },
  card: {
    minWidth: 130,
    padding: 7,
  },
  icon: {
    fontSize: 50,
  },
}));

const SummaryGrid = props => {
  const { portions, preparation, cooking, difficulty } = props;

  const [spacing] = React.useState(2);
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={spacing}>
          <Grid item>
            <Card className={classes.card}>
              <CardContent>
                <Grid container justify="center">
                  <LocalPizzaIcon className={classes.icon}/>
                </Grid>
                <Typography className={classes.title} align="center">
                  Porções
                </Typography>
                <Typography align="center" className={classes.subtitle}>
                  {portions} {portions > 1 ? "pessoas" : "pessoa"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.card}>
              <CardContent>
                <Grid container justify="center">
                  <TimerIcon className={classes.icon}/>
                </Grid>
                <Typography className={classes.title} align="center">
                  Preparo
                </Typography>
                <Typography align="center" className={classes.subtitle}>
                  {preparation <= 60?
                   preparation.toString().concat(' minutos') :
                   (preparation/60 >> 0).toString().concat(':', (preparation % 60).toString(), ' horas')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.card}>
              <CardContent>
                <Grid container justify="center">
                  <EmojiFoodBeverageIcon className={classes.icon}/>
                </Grid>
                <Typography className={classes.title} align="center">
                  Cozimento
                </Typography>
                <Typography align="center" className={classes.subtitle}>
                  {cooking <= 60?
                   cooking.toString().concat(' minutos') :
                   (cooking/60 >> 0).toString().concat(':', (cooking % 60).toString(), ' horas')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card className={classes.card}>
              <CardContent>
                <Grid container justify="center">
                  <SpeedIcon className={classes.icon}/>
                </Grid>
                <Typography className={classes.title} align="center">
                  Dificuldade
                </Typography>
                <Typography align="center" className={classes.subtitle}>
                  {difficulty}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SummaryGrid
