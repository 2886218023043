import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  dividerFullWidth: {
    margin: `10px 0 0 ${theme.spacing(2)}px`,
    fontSize: 15,
    fontWeight: 'bold'
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}px`,
  },
  divider: {
    marginBottom: 15
  },
}));

const SectionDivider = props => {
  const { title } = props;

  const classes = useStyles();

  return (
    <List className={classes.root}>
      <Divider component="li" className={classes.divider} />
      {title && (
        <h1>
          {title}
        </h1>
      )}
    </List>
  );
}

export default SectionDivider
