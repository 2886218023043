import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    '& > span': {
      fontSize: '15px',
    },
  }
}));

const UnorderedList = props => {
  const { items } = props;

  const classes = useStyles();

  return (
    <List component="nav" className={classes.root} aria-label="contacts">
      {items.map(value => {
        return (
          <ListItem button>
            <ListItemText className={classes.content}>
              <InfoIcon /> <span dangerouslySetInnerHTML={{ __html: value }} />
            </ListItemText>
          </ListItem>
        );
      })}

    </List>
  );
}

export default UnorderedList
