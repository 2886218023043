import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
    marginBottom: 15
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  avatar: {
    fontSize: '15px',
    fontWeight: 'bold',
    '& > span': {
      fontSize: '15px',
    },
  },
}));

const OrderedList = props => {
  const { items } = props;

  const classes = useStyles();
  const [dense] = React.useState(false);

  return (
    <div className={classes.root}>
      <List dense={dense}>
        {items.map((value, index) => {
          return (
            <ListItem>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  {index + 1}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={value}
                className={classes.avatar}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

export default OrderedList
